import React, { useEffect, useState, useRef } from "react"
import { useAuthUser } from "react-auth-kit"
import List from "../../components/Stats/List"
const HomePage = () => {
	const auth = useAuthUser()
	const user = auth()

	const [token] = useState(
		"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiN2JjNThjNTUtMWFmZC00YjVhLTlhNzEtN2QxMWQ2MTFhZDQxIiwidXNlcm5hbWUiOiJncm91cGFkbWluMkBjYXJlZ2l2ZXIuY29tIiwic3RhdHVzIjoiQUNUSVZFIiwidHlwZSI6Imdyb3VwLWFkbWluIiwicm9sZXMiOlsiR1JPVVBfTUFOQUdFTUVOVF9BRE1JTiJdLCJwZXJtaXNzaW9ucyI6W119LCJpYXQiOjE2MDU3NjIwMjIsImV4cCI6MTYwODM1NDAyMn0.SBfLq-aPMco3gdqM4JAyJ8xMc_0cxv1-JZ9mgyXhFx0"
	)
	const [url] = useState(
		`https://api.baobabcircle.com/care-giver/patients/search`
	)
	const [country, setCountry] = useState("254")
	const [contacts, setContacts] = useState("")
	const [phone, setPhone] = useState("")
	const [phoneError, setPhoneError] = useState("")
	const [loading, setLoading] = useState(false)
	const phoneRef = useRef(null)

	const [register, setRegister] = useState("Fetching Data")
	const [attendanceColor, setAttendanceColor] = useState("#d1d1d1")

	const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0")
	const currentYear = new Date().getFullYear()
	const currentDay = new Date().getDate()

	const attendances_url = `https://staging-api.baobabcircle.com/api/v2/che-tool/attendances?startDate=${currentYear}-${currentMonth}-${currentDay}&endDate=${currentYear}-${currentMonth}-${currentDay}&page=0&size=20&attendanceTypes=A&attendanceTypes=P&attendanceTypes=PL&attendanceTypes=SL&attendanceTypes=UL&attendanceTypes=H`
	useEffect(() => {
		fetch(attendances_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				if (result.content[0].attendanceType === "P") {
					setRegister("Present")
					setAttendanceColor("#3fa456")
				} else if (result.content[0].attendanceType) {
					setRegister("Away")
					setAttendanceColor("#ff7373")
				} else {
					setRegister("Please mark attendance")
					setAttendanceColor("#d1d1d1")
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}, [attendances_url, user.apikey])

	const handleCountry = (event) => {
		setCountry(event.target.value)
	}
	const handlePhone = (event) => {
		event.preventDefault()
		setLoading(true)
		setPhone(event.target.value)
		if (event.target.value.length < 7 && event.target.value.length >= 1) {
			setLoading(false)
			setContacts("")
			setPhoneError("Enter a complete number")
		} else if (event.target.value.length === 0) {
			setLoading(false)
			setContacts("")
			setPhoneError("")
			return
		} else {
			const number = country + phone
			fetch(url, {
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					Authorization: "Bearer " + token,
				},
				body: JSON.stringify({
					msisdn: number,
				}),
			})
				.then((response) => response.json())
				.then((result) => {
					if (result.message === "RESULTS FOUND.") {
						setContacts(result.data.patients)
						setLoading(false)
					} else {
						setLoading(false)
						setPhoneError(
							"No contacts found,please check your number and try again"
						)
					}
				})
				.catch((error) => {
					console.log(error)
					setLoading(false)
					setPhoneError("Something went wrong, please try again")
				})
			setPhoneError("")
		}
	}
	useEffect(() => {
		return function () {
			window.scrollTo(0, 0)
		}
	}, [])

	return (
		<section className="max-w-[1090px] w-full mx-auto px-[19px] sm:grid sm:pb-[80px]">
			<div className="flex justify-between py-[19px]">
				<div className="">
					<div className="text-[20px] font-bold">Search Contacts</div>
					<div
						className="text-[13px] mt-[3px]"
						style={{ color: attendanceColor }}
					>
						{register}
					</div>
				</div>
			</div>
			<div className="bg-white p-[19px] grid gap-[14px] rounded-[2px] sm:mt-[19px] mt-[10px] w-full sm:order-3">
				<div className="flex">
					<div className="text-[17px] font-medium">
						Search Contacts
					</div>
				</div>
				<ul className="grid sm:flex sm:flex-col md:grid-cols-[1fr_1fr_133px] gap-[16px]">
					<li className="flex flex-col">
						<div className="text-[13px] font-medium] pl-[10px] pb-[5px]">
							Country Code
						</div>
						<select
							id="countries"
							name="countries"
							className="border-[#f2994a] border-[1px] rounded-[3px] text-[#f2994a] h-[32px] my-[2px] ml-[2px] outline-none  px-[5px] text-[14px]"
							onChange={handleCountry}
						>
							<option value="254" defaultValue>
								Kenya (+254)
							</option>
							<option value="256">Uganda (+256)</option>
							<option value="263">Zimbabwe (+263)</option>
						</select>
					</li>
					<li className="flex flex-col">
						<div className="text-[13px] font-medium] pl-[10px] pb-[5px]">
							Phone Number
						</div>
						<input
							required
							id="phone"
							ref={phoneRef}
							onBlur={handlePhone}
							type="number"
							placeholder="Phone number"
							className="  text-[#333333] rounded-[3px] h-[32px] my-[2px] ml-[2px] outline-none  px-[10px] text-[14px] shadow-inner-custom"
						/>
					</li>
					<li className="flex flex-col">
						<div className="sm:hidden text-[13px] font-medium] pl-[10px] pb-[5px] opacity-0">
							Action
						</div>
						<button className="text-white bg-[#f2994a] rounded-[3px] flex h-[32px] text-[14px] mt-[2px]">
							<span className="m-auto flex justify-center gap-[0.2rem]">
								<span className="text-[12px]">Lookup User</span>
								<span>
									<svg
										className="w-[14px]"
										viewBox="0 0 16 19"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M14 13.9247L10.9273 10.852C11.6657 9.96558 12.0339 8.82859 11.9553 7.67759C11.8768 6.52659 11.3575 5.45018 10.5055 4.6723C9.65352 3.89442 8.53443 3.47495 7.38105 3.50116C6.22766 3.52737 5.12878 3.99723 4.31301 4.81301C3.49723 5.62878 3.02737 6.72766 3.00116 7.88105C2.97495 9.03443 3.39442 10.1535 4.1723 11.0055C4.95018 11.8575 6.02659 12.3768 7.17759 12.4553C8.32859 12.5339 9.46558 12.1657 10.352 11.4273L13.4247 14.5L14 13.9247ZM3.82829 7.9901C3.82829 7.26586 4.04305 6.55789 4.44541 5.95571C4.84778 5.35352 5.41968 4.88418 6.08879 4.60703C6.7579 4.32987 7.49417 4.25736 8.20449 4.39865C8.91481 4.53994 9.56729 4.88869 10.0794 5.40081C10.5915 5.91292 10.9403 6.5654 11.0816 7.27572C11.2229 7.98604 11.1503 8.72231 10.8732 9.39142C10.596 10.0605 10.1267 10.6324 9.5245 11.0348C8.92232 11.4372 8.21434 11.6519 7.4901 11.6519C6.51926 11.6508 5.58849 11.2647 4.902 10.5782C4.21551 9.89172 3.82936 8.96095 3.82829 7.9901Z"
											fill="white"
											stroke="white"
											stroke-width="0.6"
										/>
									</svg>
								</span>
							</span>
						</button>
					</li>
				</ul>
			</div>
			<div className="bg-white p-[19px] grid gap-[14px] rounded-[2px] mt-[19px] sm:order-4 w-full flex-grow">
				<div className="flex justify-between">
					<div className="text-[17px] font-medium">Users</div>
				</div>
				<div className="border-t-[1px] border-[#fff5eb] h-full grow-[1]">
					{contacts && <List contacts={contacts} />}
					{!contacts && (
						<div className="py-[3rem]">
							<p className="text-center">{phoneError}</p>
						</div>
					)}
					{loading && (
						<div className="loader-container">
							<svg
								className="loader"
								version="1.1"
								id="loader-1"
								xmlns="http://www.w3.org/2000/svg"
								x="0px"
								y="0px"
								width="2.50rem"
								height="2.50rem"
								viewBox="0 0 40 40"
								enableBackground="new 0 0 40 40"
							>
								<path
									opacity="0.2"
									d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
								/>
								<path
									d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z"
								>
									<animateTransform
										attributeType="xml"
										attributeName="transform"
										type="rotate"
										from="0 20 20"
										to="360 20 20"
										dur="0.5s"
										repeatCount="indefinite"
									/>
								</path>
							</svg>
						</div>
					)}
				</div>
			</div>
		</section>
	)
}
export default HomePage
