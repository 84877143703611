import React, { useState, useEffect } from "react"

function Entry({ item }) {
	const [time, setTime] = useState(0)

	useEffect(() => {
		const pastDate = new Date(item.createdAt)
		const currentDate = new Date()

		const timeDifference = currentDate - pastDate

		const daysDifference = Math.floor(
			timeDifference / (1000 * 60 * 60 * 24)
		)

		setTime(daysDifference)
	}, [item.createdAt])
	return (
		<li
			key={item.id}
			className="py-[10px] flex flex-row justify-between border-b-[1px] border-[#fff5eb]"
		>
			<div className="flex flex-col gap-[5px]">
				<div className="text-[14px] leading-[1]">{item.user.name}</div>
				<div className="text-[12px] leading-[1] text-[#6C6C6C]">
					Name
				</div>
			</div>
			<div className="hidden md:flex flex-col gap-[5px] ">
				<div className="text-[14px] leading-[1]">
					{item.user.countryCode}
				</div>
				<div className="text-[12px] leading-[1] text-[#6C6C6C]">
					Country
				</div>
			</div>
			<div className="hidden md:flex flex-col gap-[5px]">
				<div className="text-[14px] leading-[1]">
					{item.user.gender}
				</div>
				<div className="text-[12px] leading-[1] text-[#6C6C6C]">
					Gender
				</div>
			</div>
			<div className="flex flex-col gap-[5px]">
				<div className="text-[14px] leading-[1]">
					{item.engagementType}
				</div>
				<div className="text-[12px] leading-[1] text-[#6C6C6C]">
					Engagement
				</div>
			</div>
			<div className="flex flex-col gap-[5px]">
				<div className="text-[14px] leading-[1]">{time} Days</div>
				<div className="text-[12px] leading-[1] text-[#6C6C6C]">
					Recorded
				</div>
			</div>
			<button
				name=""
				id=""
				className="text-[#F19B3E] sm:hidden bg-[#FEF7F0] hover:bg-[#F19B3E] hover:text-[#ffffff] active:bg-[#ffffff] active:text-[#F19B3E] text-[12px] leading-[27px] pb-[2px] rounded-[2px] px-[15px]"
			>
				View User
			</button>
		</li>
	)
}

export default Entry
