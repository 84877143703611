import React, { useState, useEffect } from "react"
import { useAuthUser } from "react-auth-kit"
import Bar from "./Bar"

function BarGraph() {
	const auth = useAuthUser()
	const user = auth()

	const [selected, setSelected] = useState("attendance")
	const [data, setData] = useState([])

	const currentYear = new Date().getFullYear()

	const [month] = useState([
		{ count: 50, date: `${currentYear}-01-01`, month: "J" },
		{ count: 50, date: `${currentYear}-02-01`, month: "F" },
		{ count: 50, date: `${currentYear}-03-01`, month: "M" },
		{ count: 50, date: `${currentYear}-04-01`, month: "A" },
		{ count: 90, date: `${currentYear}-05-01`, month: "M" },
		{ count: 50, date: `${currentYear}-06-01`, month: "J" },
		{ count: 50, date: `${currentYear}-07-01`, month: "J" },
		{ count: 50, date: `${currentYear}-08-01`, month: "A" },
		{ count: 50, date: `${currentYear}-09-01`, month: "S" },
		{ count: 50, date: `${currentYear}-10-01`, month: "O" },
		{ count: 90, date: `${currentYear}-11-01`, month: "N" },
		{ count: 50, date: `${currentYear}-12-01`, month: "D" },
	])

	const stats_url = `https://staging-api.baobabcircle.com/api/v2/che-tool/statistics?startDate=${currentYear}-01-01&statisticType=${selected}&endDate=${currentYear}-12-31&groupByType=month`

	useEffect(() => {
		fetch(stats_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setData(result)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [stats_url, user.apikey])

	const handleData = (event) => {
		setSelected(event.target.value)
	}
	return (
		<div className="bg-white p-[19px] grid gap-[19px] rounded-[2px] grow-[1] md:min-w-[565px] sm:order-2">
			<div className="flex justify-between">
				<div className="text-[17px] font-medium capitalize">
					{selected} Trends
				</div>
				<select
					name=""
					id=""
					className="text-[#F19B3E] text-[12px] leading-[27px] pb-[2px] rounded-[2px] px-[10px] shadow-inner-custom-border"
					onChange={handleData}
				>
					<option value="attendance" defaultValue>
						Attendance
					</option>
					<option value="engagement">Engagement</option>
					<option value="onboarding">Onboarding</option>
				</select>
			</div>
			<div className="md:h-[286px] h-[240px] flex gap-[5px] md:gap-[10px]">
				<ul className="h-full flex flex-col justify-between pb-[1.2rem]">
					<li className="text-[#6C6C6C] text-[12px]">100</li>
					<li className="text-[#6C6C6C] text-[12px]">80</li>
					<li className="text-[#6C6C6C] text-[12px]">60</li>
					<li className="text-[#6C6C6C] text-[12px]">40</li>
					<li className="text-[#6C6C6C] text-[12px]">20</li>
					<li className="text-[#6C6C6C] text-[12px]">0</li>
				</ul>
				<div className=" grid grid-rows-[1fr_14px] gap-[10px] w-full">
					<ul className="h-full gap-[4px] md:gap-[15px] flex flex-row border-l-[1px] border-b-[1px] border-[#fff5eb] w-full pl-[16px]">
						{month &&
							month.map((day, index) => {
								return (
									<li
										key={index}
										className="h-full w-full md:min-w-[24px] flex flex-col-reverse bg-[#fff5eb] rounded-t-[2px]"
									>
										<Bar day={day} data={data} />
									</li>
								)
							})}
					</ul>
					<ul className="h-full  gap-[4px] md:gap-[15px] flex flex-row w-full pl-[16px]">
						{month &&
							month.map((day, index) => {
								return (
									<li
										key={index}
										className="text-[#6C6C6C] md:min-w-[24px] text-[12px] w-full text-center"
									>
										{day.month}
									</li>
								)
							})}
					</ul>
				</div>
			</div>
		</div>
	)
}

export default BarGraph
