import React, { useEffect, useState, useRef } from "react"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router"
import StandardButton from "../../components/StandardButton/StandardButton"
import useUser from "../../context/UserContext.jsx"
import styles from "./ResetPasswordPage.module.scss"
const ResetPasswordPage = () => {
	const navigate = useNavigate()
	const [referral, setReferral] = useState("")
	const [country, setCountry] = useState("+254")
	const [phone, setPhone] = useState("")
	const [countryError, setCountryError] = useState("")
	const [phoneError, setPhoneError] = useState("")
	const [referralError, setReferralError] = useState("")
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	const phoneRef = useRef(null)
	const referralRef = useRef(null)

	const { registerUser } = useUser()

	const handleCountry = (event) => {
		setCountry(event.target.value)
		if (!event.target.value) {
			setCountryError("please set your country")
		} else {
			setCountryError("")
		}
	}
	const handlePhone = (event) => {
		setPhone(event.target.value)
		if (event.target.value.length < 7 && event.target.value.length >= 1) {
			setPhoneError("please check your phone number")
		} else {
			setPhoneError("")
		}
	}
	const handleReferral = (event) => {
		setReferral(event.target.value.toUpperCase())
		if (
			!event.target.value.toUpperCase().startsWith("CHE") &&
			event.target.value.length >= 1
		) {
			setReferralError("please check your referral number")
		} else {
			setReferralError("")
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault()
		await fetch(
			"https://staging-temp.baobabcircle.com/cms/pub/referrer/reset-password",
			{
				method: "POST",
				headers: {
					Accept: "application/json",
					"Content-Type": "application/json",
					"Content-Length": 27,
				},
				body: JSON.stringify({
					code: referral,
					phone: country + phone,
				}),
			}
		)
			.then((response) => response.json())
			.then((result) => {
				if (result.data) {
					registerUser({ referral: referral, phone: country + phone })
					setLoading(false)
					navigate("/confirmation")
				} else {
					throw new Error("Something went wrong")
				}
			})
			.catch((error) => {
				setLoading(false)
				console.log(error)
				setError(error.toString())
			})
	}
	useEffect(() => {
		return function () {
			window.scrollTo(0, 0)
		}
	}, [])
	return (
		<div className={styles.login_page}>
			<div className={styles.logo}>
				<svg
					viewBox="0 0 125 98"
					fill="none"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path
						d="M12.3733 94.9296H5.48363L4.63999 97.421H0L6.23353 80.9355H11.6L17.8569 97.421H13.2169L12.3733 94.9296ZM6.60847 91.3438H11.2719L8.92847 84.6378L6.60847 91.3438Z"
						fill="#F48633"
					/>
					<path
						d="M21.0674 80.9607H33.2298V84.5465H25.3559V87.2941H33.0658V90.9032H25.3559V97.4229H21.0908V80.9607H21.0674Z"
						fill="#F48633"
					/>
					<path
						d="M42.3693 90.7868L36.0654 80.9607H40.8929L44.5018 87.038L48.0638 80.9607H52.9147L46.6577 90.7868V97.4229H42.3693V90.7868Z"
						fill="#F48633"
					/>
					<path
						d="M65.1702 94.9296H58.2805L57.4369 97.421H52.7734L59.007 80.9355H64.3734L70.6772 97.421H66.0138L65.1702 94.9296ZM59.4053 91.3438H64.0453L61.7019 84.6378L59.4053 91.3438Z"
						fill="#F48633"
					/>
					<path
						d="M77.5107 80.9912H84.7754C88.2437 80.9912 90.1887 83.3662 90.1887 86.1371C90.1887 88.908 88.2202 91.283 84.7754 91.283H80.4166V97.4301H77.5107V80.9912ZM84.4004 83.5292H80.44V88.7683H84.4004C85.8534 88.8614 87.1188 87.7903 87.2125 86.3467C87.3063 84.903 86.2283 83.6457 84.7754 83.5525C84.6348 83.5059 84.5176 83.5059 84.4004 83.5292Z"
						fill="#003C5B"
					/>
					<path
						d="M103.241 94.2865H95.39L94.1714 97.4299H90.9844L97.4991 80.991H101.131L107.623 97.4299H104.436L103.241 94.2865ZM96.2102 91.7252H102.42L99.327 83.4126L96.2102 91.7252Z"
						fill="#003C5B"
					/>
					<path
						d="M111.42 80.991H118.685C122.153 80.991 124.098 83.366 124.098 86.1369C124.098 88.9077 122.129 91.2827 118.685 91.2827H114.326V97.4299H111.42V80.991ZM118.31 83.529H114.349V88.768H118.31C119.763 88.8612 121.028 87.7901 121.122 86.3464C121.215 84.9028 120.138 83.6454 118.685 83.5523C118.544 83.5057 118.427 83.5057 118.31 83.529Z"
						fill="#003C5B"
					/>
					<g filter="url(#filter0_d_1_6456)">
						<path
							d="M51.9699 54.7176C51.9699 54.7176 77.3548 59.229 91.7328 46.2417C106.111 33.2543 104.047 7.75814 104.047 7.75814C104.047 7.75814 78.5931 3.24674 64.284 16.2341C49.9748 29.2214 51.9699 54.7176 51.9699 54.7176Z"
							fill="white"
						/>
					</g>
					<g filter="url(#filter1_d_1_6456)">
						<path
							d="M72.5391 54.7176C72.5391 54.7176 47.1541 59.229 32.7762 46.2417C18.3982 33.2543 20.4621 7.75814 20.4621 7.75814C20.4621 7.75814 45.847 3.24674 60.225 16.2341C74.6029 29.2214 72.5391 54.7176 72.5391 54.7176Z"
							fill="#F48633"
						/>
					</g>
					<path
						opacity="0.52"
						d="M51.9699 54.7176C51.9699 54.7176 77.3548 59.229 91.7328 46.2417C106.111 33.2543 104.047 7.75814 104.047 7.75814C104.047 7.75814 78.5931 3.24674 64.284 16.2341C49.9748 29.2214 51.9699 54.7176 51.9699 54.7176Z"
						fill="#003C5B"
					/>
					<defs>
						<filter
							id="filter0_d_1_6456"
							x="44.875"
							y="0"
							width="66.2744"
							height="62.4758"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood
								floodOpacity="0"
								result="BackgroundImageFix"
							/>
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
								result="hardAlpha"
							/>
							<feOffset />
							<feGaussianBlur stdDeviation="3.5" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
							/>
							<feBlend
								mode="normal"
								in2="BackgroundImageFix"
								result="effect1_dropShadow_1_6456"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="effect1_dropShadow_1_6456"
								result="shape"
							/>
						</filter>
						<filter
							id="filter1_d_1_6456"
							x="16.3594"
							y="3"
							width="60.2822"
							height="56.4758"
							filterUnits="userSpaceOnUse"
							colorInterpolationFilters="sRGB"
						>
							<feFlood
								floodOpacity="0"
								result="BackgroundImageFix"
							/>
							<feColorMatrix
								in="SourceAlpha"
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
								result="hardAlpha"
							/>
							<feOffset />
							<feGaussianBlur stdDeviation="2" />
							<feColorMatrix
								type="matrix"
								values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
							/>
							<feBlend
								mode="normal"
								in2="BackgroundImageFix"
								result="effect1_dropShadow_1_6456"
							/>
							<feBlend
								mode="normal"
								in="SourceGraphic"
								in2="effect1_dropShadow_1_6456"
								result="shape"
							/>
						</filter>
					</defs>
				</svg>
			</div>
			<form className={styles.form} onSubmit={handleSubmit}>
				<h1>Create your account</h1>
				<p>Please enter your details to create your account</p>
				<div className={styles.form_fields}>
					<div className={styles.input}>
						<label htmlFor="phone">Phone number</label>
						<div className={styles.phone_input}>
							<select
								id="countries"
								name="countries"
								className={
									countryError ? styles.input_error : ""
								}
								onChange={handleCountry}
							>
								<option value="+254" defaultValue>
									+254
								</option>
								<option value="+256">+256</option>
								<option value="+263">+263</option>
							</select>
							<input
								required
								id="phone"
								ref={phoneRef}
								type="number"
								placeholder="Phone number"
								className={phoneError ? styles.input_error : ""}
								onBlur={handlePhone}
							/>
						</div>
					</div>
					<div className={styles.input}>
						<label htmlFor="referral">Referral number</label>
						<input
							required
							id="referral"
							ref={referralRef}
							type="text"
							placeholder="Enter here"
							className={referralError ? styles.input_error : ""}
							onBlur={handleReferral}
						/>
					</div>
				</div>
				{countryError && <p className={styles.error}>{countryError}</p>}
				{phoneError && <p className={styles.error}>{phoneError}</p>}
				{referralError && (
					<p className={styles.error}>{referralError}</p>
				)}
				<div className={styles.controls}>
					{error && <p className="error">{error}</p>}
					<StandardButton
						loading={loading}
						disabled={countryError || phoneError || referralError}
						type="submit"
					>
						Sign Up
					</StandardButton>
				</div>
				<p>
					Don't have an account?{" "}
					<Link className="link" to="/register">
						Click Here
					</Link>
				</p>
			</form>
		</div>
	)
}
export default ResetPasswordPage
