import React, { useState, useEffect } from "react"

function Day({ day, roster, goals }) {
	const [itemDate, setItemDate] = useState("")
	const [goal, setGoal] = useState(null)
	const [attandanceCalender, setAttandanceCalender] = useState({
		attendanceType: "",
		date: "",
		endTime: "",
		id: 3,
		startTime: "",
		timezone: "",
	})
	const monthNames = [
		"Jan",
		"Feb",
		"Mar",
		"Apr",
		"May",
		"Jun",
		"July",
		"Aug",
		"Sep",
		"Oct",
		"Nov",
		"Dec",
	]

	const getColor = (status) => {
		let color
		switch (status) {
			case "Today":
				color = "#F19B3E"
				break
			case "P":
				color = "#3FA456"
				break
			case "A":
				color = "#FF7373"
				break
			case "H":
				color = "#6C6C6C"
				break
			case "PL":
				color = "#6C6C6C"
				break
			case "SL":
				color = "#6C6C6C"
				break
			case "UL":
				color = "#FF7373"
				break
			default:
				color = "#D1D1D1"
		}
		return color
	}
	const getBackgroundColor = (status) => {
		let color
		switch (status) {
			case "Today":
				color = "#FEF9F3"
				break
			case "P":
				color = "#E3F3DB"
				break
			case "A":
				color = "#FFEEEE"
				break
			case "H":
				color = "#FBFBFB"
				break
			case "PL":
				color = "#FBFBFB"
				break
			case "SL":
				color = "#FBFBFB"
				break
			case "UL":
				color = "#FFEEEE"
				break
			default:
				color = "#FBFBFB"
		}
		return color
	}

	useEffect(() => {
		setItemDate(
			`${day.date.getFullYear()}-${String(
				day.date.getMonth() + 1
			).padStart(2, "0")}-${String(day.date.getDate()).padStart(2, "0")}`
		)

		const value = roster.find((item) => item.date === itemDate)
		const target = goals.find((item) => item.date === itemDate)

		if (target) {
			setGoal(target.current)
		} else {
			setGoal(null)
		}

		if (value) {
			setAttandanceCalender(value)
		} else if (
			`${new Date().getFullYear()}-${String(
				new Date().getMonth() + 1
			).padStart(2, "0")}-${String(new Date().getDate()).padStart(
				2,
				"0"
			)}` === itemDate
		) {
			setAttandanceCalender({
				attendanceType: "Today",
				date: itemDate,
			})
		} else {
			return
		}
	}, [day.date, goals, itemDate, roster])
	return (
		<li
			className=" md:min-w-[60px] flex flex-col gap-[5px] overflow-hidden rounded-b-[2px] px-[5px] pt-[9px] pb-[10px] cursor-pointer hover:opacity-[0.8]"
			style={{
				boxShadow: `inset 0 0.1rem ${getColor(
					attandanceCalender.attendanceType
				)}`,
				backgroundColor: getBackgroundColor(
					attandanceCalender.attendanceType
				),
				color: getColor(attandanceCalender.attendanceType),
			}}
		>
			<div className="flex flex-row w-full justify-between">
				<div className="text-[#000000]">
					<span className="text-[16px] font-[500]">
						{new Date(day.date).getDate()}
					</span>{" "}
					<br className="md:hidden" />
					<span className="text-[10px] uppercase font-[500]">
						{monthNames[new Date(day.date).getMonth()]}
					</span>
				</div>
				<div className="flex">
					{goal === 0 &&
						attandanceCalender.attendanceType === "P" && (
							<img
								alt="perfomance"
								src="/assets/bad.png"
								className="h-[14px] w-[14px] m-auto"
							/>
						)}
					{goal === 10 &&
						attandanceCalender.attendanceType === "P" && (
							<img
								alt="perfomance"
								src="/assets/good.png"
								className="h-[14px] w-[14px] m-auto"
							/>
						)}
					{goal > 10 && attandanceCalender.attendanceType === "P" && (
						<img
							alt="perfomance"
							src="/assets/perfect.png"
							className="h-[14px] w-[14px] m-auto"
						/>
					)}
				</div>
			</div>
			<div
				className="text-[10px] border-l-[1px] pl-[5px] leading-[1]"
				style={{
					borderLeft: `1px solid ${getColor(
						attandanceCalender.attendanceType
					)}`,
				}}
			>
				{attandanceCalender.attendanceType || "null"}
			</div>
		</li>
	)
}

export default Day
