import React from "react"
import styles from "./StandardButton.module.scss"
const StandardButton = ({ children, onClick, type, disabled, loading }) => {
	return (
		<button
			className={loading ? styles.loading_button : styles.standard_button}
			onClick={onClick}
			type={type}
			disabled={disabled}
		>
			{!loading && children}
			{loading && (
				<svg
					className={styles.loader}
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					x="0px"
					y="0px"
					viewBox="0 0 24 30"
					enableBackground="new 0 0 40 40"
				>
					<rect x="0" y="10" width="4" height="10" opacity="0.2">
						<animate
							attributeName="opacity"
							attributeType="XML"
							values="0.2; 1; .2"
							begin="0s"
							dur="0.6s"
							repeatCount="indefinite"
						/>
						<animate
							attributeName="height"
							attributeType="XML"
							values="10; 20; 10"
							begin="0s"
							dur="0.6s"
							repeatCount="indefinite"
						/>
						<animate
							attributeName="y"
							attributeType="XML"
							values="10; 5; 10"
							begin="0s"
							dur="0.6s"
							repeatCount="indefinite"
						/>
					</rect>
					<rect x="8" y="10" width="4" height="10" opacity="0.2">
						<animate
							attributeName="opacity"
							attributeType="XML"
							values="0.2; 1; .2"
							begin="0.15s"
							dur="0.6s"
							repeatCount="indefinite"
						/>
						<animate
							attributeName="height"
							attributeType="XML"
							values="10; 20; 10"
							begin="0.15s"
							dur="0.6s"
							repeatCount="indefinite"
						/>
						<animate
							attributeName="y"
							attributeType="XML"
							values="10; 5; 10"
							begin="0.15s"
							dur="0.6s"
							repeatCount="indefinite"
						/>
					</rect>
					<rect x="16" y="10" width="4" height="10" opacity="0.2">
						<animate
							attributeName="opacity"
							attributeType="XML"
							values="0.2; 1; .2"
							begin="0.3s"
							dur="0.6s"
							repeatCount="indefinite"
						/>
						<animate
							attributeName="height"
							attributeType="XML"
							values="10; 20; 10"
							begin="0.3s"
							dur="0.6s"
							repeatCount="indefinite"
						/>
						<animate
							attributeName="y"
							attributeType="XML"
							values="10; 5; 10"
							begin="0.3s"
							dur="0.6s"
							repeatCount="indefinite"
						/>
					</rect>
				</svg>
			)}
		</button>
	)
}
export default StandardButton
