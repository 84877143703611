export const initialState = {
	referral: "",
	phone: "",
	auth: "",
}

const userReducer = (state, action) => {
	const { type, payload } = action

	switch (type) {
		case "REGISTER_USER":
			return {
				...state,
				referral: payload.data.referral,
				phone: payload.data.phone,
			}
		case "AUTHORIZE_USER":
			return {
				...state,
				auth: payload.apiKey,
			}
		default:
			throw new Error(`Case for type ${type} does not exist`)
	}
}

export default userReducer
