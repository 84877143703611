import React, { useState, useEffect } from "react"
import { createPortal } from "react-dom"

function Overlay({ isOpen, onClick, onClose }) {
	const [isMounted, setIsMounted] = useState(false)
	useEffect(() => {
		setIsMounted(true)
		return () => setIsMounted(false)
	}, [])

	if (!isMounted || !isOpen) return null
	return createPortal(
		<div
			onClick={onClose}
			className="bg-black/[.3] z-[10] h-[100dvh] w-[100dvw] fixed top-0 left-0 "
		></div>,
		document.getElementById("modal-root")
	)
}

export default Overlay
