import { createContext, useReducer, useContext } from "react"
import userReducer, { initialState } from "../reducers/userReducer"

const UserContext = createContext(initialState)

export const UserProvider = ({ children }) => {
	const [state, dispatch] = useReducer(userReducer, initialState)

	const registerUser = (data) => {
		dispatch({
			type: "REGISTER_USER",
			payload: {
				data: data,
			},
		})
	}
	const authorizeUser = (key) => {
		dispatch({
			type: "AUTHORIZE_USER",
			payload: {
				apiKey: key,
			},
		})
	}

	const value = {
		referral: state.referral,
		phone: state.phone,
		auth: state.auth,
		registerUser,
		authorizeUser,
	}

	return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}

const useUser = () => {
	const context = useContext(UserContext)

	if (context === undefined) {
		throw new Error(
			"Unable to access context, useApp was used outside the AppContext"
		)
	}
	return context
}

export default useUser
