import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"

import { AuthProvider } from "react-auth-kit"
import reportWebVitals from "./reportWebVitals"
require("normalize.css")
const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
	<React.StrictMode>
		<AuthProvider
			authType={"cookie"}
			authName={"_auth"}
			cookieDomain={window.location.hostname}
			cookieSecure={true}
		>
			<App />
		</AuthProvider>
	</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
