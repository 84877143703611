import React, { useState, useEffect } from "react"

function Bar({ day, data }) {
	const [goal, setGoal] = useState(null)

	useEffect(() => {
		const value = data.find((item) => item.label === day.date)
		if (value) {
			setGoal(value.total)
		} else {
			setGoal(0)
		}
	}, [day, data])

	return (
		<div
			className="bg-[#f19b3e] w-full  rounded-t-[2px] transition-height duration-500 ease-in-out"
			style={{
				height: (goal / 100) * 100 + "%",
			}}
		></div>
	)
}

export default Bar
