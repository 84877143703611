import React, { useState, useEffect } from "react"
import CallScreen from "../../components/CallScreen/CallScreen"
import Overlay from "../../components/Overlay"

function ContactItem({ contact, onClose }) {
	const [dial, setDial] = useState(false)

	const handleCall = () => {
		if (dial) {
			setDial(!dial)
		} else {
			setDial(!dial)
		}
	}

	useEffect(() => {})
	return (
		<li
			key={contact.id}
			className="py-[10px] flex flex-row justify-between border-b-[1px] border-[#fff5eb]"
		>
			<div className="flex flex-col gap-[5px]">
				<div className="text-[14px] leading-[1]">
					{contact.firstName}
				</div>
				<div className="text-[12px] leading-[1] text-[#6C6C6C]">
					First Name
				</div>
			</div>
			<div className="hidden md:flex flex-col gap-[5px] ">
				<div className="text-[14px] leading-[1]">{contact.country}</div>
				<div className="text-[12px] leading-[1] text-[#6C6C6C]">
					Country
				</div>
			</div>
			<div className="hidden md:flex flex-col gap-[5px]">
				<div className="text-[14px] leading-[1]">{contact.gender}</div>
				<div className="text-[12px] leading-[1] text-[#6C6C6C]">
					Gender
				</div>
			</div>
			<div className="flex flex-col gap-[5px]">
				<div className="text-[14px] leading-[1]">{contact.msisdn}</div>
				<div className="text-[12px] leading-[1] text-[#6C6C6C]">
					Phone
				</div>
			</div>
			<div className="flex sm:hidden flex-col gap-[5px]">
				<div className="text-[14px] leading-[1]">{contact.status}</div>
				<div className="text-[12px] leading-[1] text-[#6C6C6C]">
					Status
				</div>
			</div>
			<button
				name=""
				id=""
				onClick={handleCall}
				className="text-[#F19B3E] bg-[#FEF7F0] hover:bg-[#F19B3E] hover:text-[#ffffff] active:bg-[#ffffff] active:text-[#F19B3E] text-[12px] leading-[27px] pb-[2px] rounded-[2px] px-[15px]"
			>
				Call User
			</button>
			{dial && (
				<CallScreen
					onEnd={handleCall}
					number={contact.msisdn}
				></CallScreen>
			)}
			<Overlay isOpen={dial} onClose={handleCall} />
		</li>
	)
}

export default ContactItem
