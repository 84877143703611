import React, { useEffect, useState } from "react"
import { useAuthUser } from "react-auth-kit"
import Calender from "../../components/calender/Calender"
import BarGraph from "../../components/Graph/BarGraph"
import MarkAttendance from "../../components/calender/MarkAttendance"
import Stats from "../../components/dashboard/Stats"
import Overlay from "../../components/Overlay"
import Entries from "../../components/Stats/Entries"
const HomePage = () => {
	const auth = useAuthUser()
	const user = auth()

	const [isOpen, setIsOpen] = useState(false)
	const [register, setRegister] = useState("Fetching Data")
	const [attendanceColor, setAttendanceColor] = useState("#d1d1d1")

	const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0")
	const currentYear = new Date().getFullYear()
	const currentDay = new Date().getDate()

	const attendances_url = `https://staging-api.baobabcircle.com/api/v2/che-tool/attendances?startDate=${currentYear}-${currentMonth}-${currentDay}&endDate=${currentYear}-${currentMonth}-${currentDay}&page=0&size=20&attendanceTypes=A&attendanceTypes=P&attendanceTypes=PL&attendanceTypes=SL&attendanceTypes=UL&attendanceTypes=H`

	useEffect(() => {
		fetch(attendances_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setIsOpen(Boolean(!result.content.length))
				if (result.content[0].attendanceType === "P") {
					setRegister("Present")
					setAttendanceColor("#3fa456")
				} else if (result.content[0].attendanceType) {
					setRegister("Away")
					setAttendanceColor("#ff7373")
				} else {
					setRegister("Please mark attendance")
					setAttendanceColor("#d1d1d1")
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}, [attendances_url, user.apikey])

	useEffect(() => {
		return function () {
			window.scrollTo(0, 0)
		}
	}, [])

	return (
		<section className="max-w-[1090px] w-full mx-auto px-[19px] sm:grid sm:pb-[80px]">
			<div className="flex justify-between py-[19px]">
				<div className="">
					<div className="text-[20px] font-bold">
						Hello {user ? user.referrer : "Guest"}
					</div>
					<div
						className="text-[13px] mt-[3px]"
						style={{ color: attendanceColor }}
					>
						{register}
					</div>
				</div>
			</div>
			<Stats />
			<div className="flex flex-wrap w-full gap-[19px] mt-[19px] sm:order-2">
				<BarGraph />
				<div className="bg-white p-[19px] flex flex-col gap-[14px] rounded-[2px] grow-[1]  md:min-w-[450px] sm:order-1">
					<div className="flex justify-between grow-0">
						<div className="text-[17px] font-medium">
							Attendance
						</div>
						<button
							onClick={() => setIsOpen(true)}
							className="text-[#F19B3E] bg-[#FEF7F0] hover:bg-[#F19B3E] hover:text-[#ffffff] active:bg-[#ffffff] active:text-[#F19B3E] text-[12px] leading-[27px] pb-[2px] rounded-[2px] px-[10px]"
						>
							Mark Attendance
						</button>
						<MarkAttendance
							isOpen={isOpen}
							onClose={() => setIsOpen(false)}
						/>
						<Overlay
							isOpen={isOpen}
							onClose={() => setIsOpen(false)}
						/>
					</div>
					<Calender />
				</div>
			</div>
			<div className="bg-white p-[19px] grid gap-[14px] rounded-[2px] mt-[19px]  mb-[19px] sm:order-4">
				<div className="flex justify-between">
					<div className="text-[17px] font-medium">
						Recent Engagements
					</div>
				</div>
				<div className="border-t-[1px] border-[#fff5eb] h-full grow-[1]">
					<Entries />
				</div>
			</div>
		</section>
	)
}
export default HomePage
