import React, { useEffect, useState } from "react"
import { useSignOut, useAuthUser } from "react-auth-kit"
import { useNavigate } from "react-router-dom"

const AccountPage = () => {
	const auth = useAuthUser()
	const user = auth()
	const singOut = useSignOut()
	const navigate = useNavigate()
	const [account, setAccount] = useState(null)
	const [register, setRegister] = useState("Fetching Data")
	const [attendanceColor, setAttendanceColor] = useState("#d1d1d1")

	const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0")
	const currentYear = new Date().getFullYear()
	const currentDay = new Date().getDate()

	const attendances_url = `https://staging-api.baobabcircle.com/api/v2/che-tool/attendances?startDate=${currentYear}-${currentMonth}-${currentDay}&endDate=${currentYear}-${currentMonth}-${currentDay}&page=0&size=20&attendanceTypes=A&attendanceTypes=P&attendanceTypes=PL&attendanceTypes=SL&attendanceTypes=UL&attendanceTypes=H`
	useEffect(() => {
		fetch(attendances_url, {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				if (result.content[0].attendanceType === "P") {
					setRegister("Present")
					setAttendanceColor("#3fa456")
				} else if (result.content[0].attendanceType) {
					setRegister("Away")
					setAttendanceColor("#ff7373")
				} else {
					setRegister("Please mark attendance")
					setAttendanceColor("#d1d1d1")
				}
			})
			.catch((error) => {
				console.log(error)
			})
	}, [attendances_url, user.apikey])

	useEffect(() => {
		fetch("https://staging-api.baobabcircle.com/api/v2/che-tool/profile", {
			method: "GET",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				apiKey: user.apikey,
			},
		})
			.then((response) => response.json())
			.then((result) => {
				setAccount(result)
			})
			.catch((error) => {
				console.log(error)
			})
	}, [user.apikey])

	const logout = () => {
		singOut()
		navigate("/login")
	}
	useEffect(() => {
		return function () {
			window.scrollTo(0, 0)
		}
	}, [])
	return (
		<section className="max-w-[535px] w-full mx-auto px-[19px] sm:grid sm:pb-[80px]">
			<div className="flex justify-between py-[19px]">
				<div className="">
					<div className="text-[20px] font-bold">Account</div>
					<div
						className="text-[13px] mt-[3px]"
						style={{ color: attendanceColor }}
					>
						{register}
					</div>
				</div>
			</div>
			<div className="">
				<div className=" bg-cover bg-[#eaeaea] border-[#FFFFFF] border-[3px] w-[100px] h-[100px] flex m-auto rounded-[10rem]">
					<svg
						className="h-[40px] pb-[3px] m-auto"
						viewBox="0 0 16 20"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M9.99171 7.01644C10.0807 6.13015 11.1484 5.48322 11.1795 5.19604C11.2154 4.86898 10.941 4.50482 10.5589 4.514C10.2259 4.52198 9.19483 5.76082 8.76046 5.63637C8.32655 5.51273 8.51242 3.99709 8.30578 3.7498C8.19132 3.61179 8.04971 3.54638 7.79045 3.54199C7.5308 3.54678 7.38919 3.61179 7.27473 3.7494C7.06816 3.99749 7.25402 5.51273 6.82005 5.63637C6.3853 5.76082 5.35466 4.52198 5.02122 4.514C4.63951 4.50482 4.3655 4.86898 4.4014 5.19604C4.43291 5.48322 5.49984 6.13015 5.58879 7.01644C5.67773 7.9031 3.80471 11.8126 3.80471 18.3167C3.80471 18.9537 6.28479 19.4986 7.79045 19.4986C9.29576 19.4986 11.7766 18.9537 11.7766 18.3167C11.7766 11.8126 9.90279 7.90263 9.99171 7.01644Z"
							fill="#15486B"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M7.79047 19.9999C6.76711 20.0028 5.75332 19.8026 4.80779 19.4112C3.86225 19.0197 3.00373 18.4446 2.28187 17.7192C1.55631 16.9981 0.98102 16.1402 0.589332 15.1952C0.197646 14.2502 -0.00265548 13.2368 2.87473e-05 12.2139C-0.00276051 11.1909 0.197452 10.1774 0.589071 9.2324C0.980688 8.28728 1.55593 7.42928 2.28147 6.70812C2.30767 6.6819 2.33875 6.66114 2.37297 6.64693C2.40719 6.63278 2.44386 6.62549 2.4809 6.62549C2.51794 6.62549 2.55461 6.63278 2.58883 6.64693C2.62305 6.66114 2.65414 6.6819 2.68033 6.70812C2.70652 6.73426 2.72729 6.76538 2.74147 6.79962C2.75564 6.8338 2.76293 6.87049 2.76293 6.90752C2.76293 6.94455 2.75564 6.98124 2.74147 7.01548C2.72729 7.04965 2.70652 7.08078 2.68033 7.10692C-0.136779 9.92284 -0.136779 14.5045 2.68033 17.3208C3.34987 17.9938 4.14622 18.5272 5.02331 18.8904C5.90038 19.2535 6.84077 19.4391 7.79007 19.4364C9.72052 19.4364 11.5345 18.6853 12.8993 17.3208C15.7169 14.5045 15.7169 9.92284 12.8993 7.10692C12.8732 7.08078 12.8524 7.04965 12.8382 7.01548C12.824 6.98124 12.8168 6.94455 12.8168 6.90752C12.8168 6.87049 12.824 6.8338 12.8382 6.79962C12.8524 6.76538 12.8732 6.73426 12.8993 6.70812C12.9256 6.6819 12.9567 6.66114 12.9909 6.64693C13.0251 6.63278 13.0618 6.62549 13.0988 6.62549C13.1358 6.62549 13.1725 6.63278 13.2067 6.64693C13.2409 6.66114 13.2721 6.6819 13.2982 6.70812C14.0239 7.42922 14.5992 8.28721 14.9908 9.23233C15.3824 10.1774 15.5826 11.1909 15.5797 12.2139C15.5826 13.2368 15.3825 14.2502 14.9908 15.1953C14.5992 16.1403 14.0239 16.9982 13.2982 17.7192C12.5764 18.4446 11.7179 19.0196 10.7725 19.4111C9.82702 19.8026 8.81375 20.0027 7.79047 19.9999Z"
							fill="#15486B"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M8.15702 3.05938C8.15702 3.05938 6.59548 3.25961 5.75709 2.42081C4.9195 1.58202 5.12251 0.0209075 5.12251 0.0209075C5.12251 0.0209075 6.68443 -0.179317 7.52245 0.659473C8.36 1.49906 8.15702 3.05938 8.15702 3.05938Z"
							fill="#F19B3E"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M10.4587 0.0257365C10.4587 0.0257365 10.659 1.58685 9.8198 2.42445C8.98065 3.26204 7.41874 3.05863 7.41874 3.05863C7.41874 3.05863 7.21848 1.49791 8.05769 0.660314C8.89764 -0.17728 10.4587 0.0257365 10.4587 0.0257365Z"
							fill="#1F598A"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.40659 4.18291C4.40659 4.18291 3.7772 5.08472 2.94918 5.12501C2.12116 5.16449 1.40841 4.3269 1.40841 4.3269C1.40841 4.3269 2.03779 3.42469 2.86581 3.38441C3.69383 3.34492 4.40659 4.18291 4.40659 4.18291Z"
							fill="#F19B3E"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M3.91561 1.56763C3.91561 1.56763 4.81822 2.19702 4.8585 3.02424C4.89799 3.85186 4.0596 4.56461 4.0596 4.56461C4.0596 4.56461 3.15739 3.93562 3.11711 3.108C3.07722 2.28038 3.91561 1.56763 3.91561 1.56763Z"
							fill="#1F598A"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M11.5747 4.60093C11.5747 4.60093 10.6717 3.97193 10.6319 3.14431C10.592 2.31669 11.4303 1.60474 11.4303 1.60474C11.4303 1.60474 12.333 2.23333 12.3729 3.06095C12.4127 3.88857 11.5747 4.60093 11.5747 4.60093Z"
							fill="#F19B3E"
						/>
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M14.1909 4.11028C14.1909 4.11028 13.5611 5.01209 12.7335 5.05237C11.9055 5.09226 11.1927 4.25427 11.1927 4.25427C11.1927 4.25427 11.8217 3.35206 12.6497 3.31217C13.4781 3.27228 14.1909 4.11028 14.1909 4.11028Z"
							fill="#1F598A"
						/>
					</svg>
				</div>
				{account && (
					<ul className="flex flex-col gap-[10px] pt-[3rem]">
						<li className="flex flex-col gap-[0.3rem] border-b-[#E2E2E2] border-b-[1px] border-t-[#E2E2E2] border-t-[1px] py-[13px]">
							<div className="text-[14px] text-[#6C6C6C] font-[400]">
								Name
							</div>
							<div className="flex justify-between">
								<div className="text-[18px] font-[500]">
									{account.user.name}
								</div>
							</div>
						</li>
						<li className="flex flex-col gap-[0.3rem] border-b-[#E2E2E2] border-b-[1px] pb-[13px]">
							<div className="text-[14px] text-[#6C6C6C] font-[400]">
								Referrer Code
							</div>
							<div className="flex justify-between">
								<div className="text-[18px] font-[500]">
									{account.referrerCode}
								</div>
							</div>
						</li>
						<li className="flex flex-col gap-[0.3rem] border-b-[#E2E2E2] border-b-[1px] pb-[13px]">
							<div className="text-[14px] text-[#6C6C6C] font-[400]">
								Phone
							</div>
							<div className="flex justify-between">
								<div className="text-[18px] font-[500]">
									{account.user.phone}
								</div>
							</div>
						</li>

						<li className="flex flex-col gap-[0.3rem] border-b-[#E2E2E2] border-b-[1px] pb-[13px]">
							<div className="text-[14px] text-[#6C6C6C] font-[400]">
								Name
							</div>
							<div className="flex justify-between">
								<div className="text-[18px] font-[500]">
									{account.countryCode}
								</div>
							</div>
						</li>
					</ul>
				)}
				<div className="grid justify-around pt-[2rem]">
					<button
						onClick={logout}
						className="text-white bg-[#ff7373] text-[18px] font-normal leading-[48px] px-[25px] rounded-[8px]"
					>
						Logout
					</button>
				</div>
			</div>
		</section>
	)
}
export default AccountPage
